var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.item.ordinativo.noteconsip)?_c('v-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v("Note Non Validato Consip: ")]),_vm._v(" "+_vm._s(_vm.item.ordinativo.noteconsip)+" ")]):_vm._e(),_c('div',{staticClass:"float-right ma-3"},[(!_vm.$auth('consip'))?_c('router-link',{attrs:{"to":{name: 'compilazione', params: {id: _vm.$route.params.id}}}},[_c('v-btn',{attrs:{"color":"warning"}},[_vm._v(" Modifica ")])],1):_vm._e(),_c('Confirm',{attrs:{"message":"Aggiungi allegato","width":"400px"},on:{"confirm":_vm.uploadRapporto},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$auth(['amministratore','ispettore']) && _vm.item.ordinativo.stato === 'eseguito'),expression:"$auth(['amministratore','ispettore']) && item.ordinativo.stato === 'eseguito'"}],staticClass:"ml-2",attrs:{"color":"success"}},'v-btn',attrs,false),on),[_vm._v(" Carica Rapporto ")])]}},{key:"default",fn:function(){return [_c('v-file-input',{attrs:{"label":"Rapporto"},model:{value:(_vm.rapporto),callback:function ($$v) {_vm.rapporto=$$v},expression:"rapporto"}})]},proxy:true}])}),(_vm.$auth(['amministratore', 'ispettore']))?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.download_rapporto}},[_vm._v(" Scarica Rapporto ")]):_vm._e()],1),_c('v-card-title',[_c('div',[_vm._v(" CDC: "+_vm._s(_vm.item.ordinativo.ordine)+" - "+_vm._s(_vm.item.ordinativo.descrizione_lotto)),_c('br'),_vm._v(" Checklist: "+_vm._s(_vm.item.checklist)+" "),_c('br')])]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('label',[_vm._v("Data Ispezione: ")]),_vm._v(" "),_c('br'),_c('LocalDate',{attrs:{"date":_vm.item.ordinativo.data_pianificazione}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v("Note: ")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.item.ordinativo.note)+" ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.item.livelli,"group-by":"categoria","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var headers = ref.headers;
var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(group))])],1)]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(item.note))])]}},{key:"item.allegati",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{attrs:{"items":item.allegati,"headers":[{value:'nomefile'},{value:'download', align: 'right'}],"hide-default-footer":"","hide-default-header":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.download(item.id)}}},[_c('v-icon',[_vm._v("mdi-file-pdf")])],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }